<template>
	<div class="p-login-consumer-error">
		login-error
	</div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="less">
.p-login-consumer-error {
}
</style>